<template>
  <div class="about">
    <h1 class="pb-3">Klaslijst</h1>
    <v-card flat>
      <p>Kies je klas</p>
      <v-list rounded class="overflow-y-auto" max-height="600">
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in items" :key="item">
            <v-list-item-title @click="klasKeuze(item)">
              {{ item }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      klas: "",
      selectedItem: "",
    };
  },
  methods: {
    klasKeuze: function (k) {
      this.klas = k;
      this.$store.commit("klasKeuze", k);
      this.$store.dispatch("bindLeerlingenKlas");
      // ga naar Fotolijst-page
      this.$router.push("/fotolijst");
    },
  },
  computed: {
    items() {
      return this.$store.getters.klassenLijst;
    },
  },
  mounted() {
    // this.$store.dispatch("bindScholenListRef");
    //this.$store.dispatch("bindScholenObjRef");
    this.$store.dispatch("bindSchoolLeerlingenListRef");
  },
};
</script>
