<template>
  <div class="about">
    <h1 class="pb-3">Scholenlijst</h1>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="scholen" :search="search">
        <template v-slot:[`item.edit`]="{ item }">
          <v-icon small class="mr-2" color="green" @click="editSchool(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small class="mr-2" color="red" @click="deleteSchool(item)"
            >mdi-delete</v-icon
          >
          <v-icon
            small
            class="mr-2"
            color="blue"
            @click="schoolKeuze(item['.key'])"
            >mdi-arrow-right-bold-circle</v-icon
          >
        </template></v-data-table
      >
    </v-card>
    <!-- <v-btn
      color="pink"
      dark
      absolute
      bottom
      right
      fab
      style="margin-bottom: 60px; margin-right: 20px"
      v-on:click="addNew()"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn> -->
    <!-- modal new school -->
    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="pink"
          dark
          absolute
          bottom
          right
          fab
          style="margin-bottom: 60px; margin-right: 20px"
          v-bind="attrs"
          v-on="on"
          @click="resetForm()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-toolbar color="blue" dark class="text-h5">School gegevens</v-toolbar>
      <v-card flat>
        <!-- <v-card-title>
          <span class="text-h5">Nieuwe School</span>
        </v-card-title> -->
        <v-card-text>
          <v-container>
            <v-row>
              <!-- <v-col cols="12" sm="6" md="4">
                <v-text-field label="Legal first name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Legal middle name"
                  hint="example of helper text only on focus"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Legal last name*"
                  hint="example of persistent helper text"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  label="Schoolnaam*"
                  required
                  v-model="formValues.schoolnaam"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Smartschoolbeheerder*"
                  required
                  v-model="formValues.sadmin"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="SO-Email Smartschoolbeheerder*"
                  required
                  type="email"
                  v-model="formValues.email"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  label="Password*"
                  type="password"
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-text-field
                  label="Telefoonnummer Smartschoolbeheerder*"
                  type="tel"
                  required
                  v-model="formValues.tel"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-select
                  :items="['0-17', '18-29', '30-54', '54+']"
                  label="Age*"
                  required
                ></v-select>
              </v-col> -->
              <!-- <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="[
                    'Skiing',
                    'Ice hockey',
                    'Soccer',
                    'Basketball',
                    'Hockey',
                    'Reading',
                    'Writing',
                    'Coding',
                    'Basejump',
                  ]"
                  label="Interests"
                  multiple
                ></v-autocomplete>
              </v-col> -->
            </v-row>
          </v-container>
          <small>*verplicht veld</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1 white--text" @click="saveSchool()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- deleteDialog -->
    <v-dialog max-width="500" v-model="deleteDialog">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="error" dark class="text-h5"
            >Verwijder de school</v-toolbar
          >
          <v-card-text>
            <div class="text-h4 mt-8 mb-2">Ben je zeker?</div>
            <p>
              Door een school te verwijderen, verwijder je alle leerkrachten en
              leerlingen van de school.
            </p>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn outlined color="red" @click="dialog.value = false"
              >Close</v-btn
            >
            <v-btn color="red white--text" @click="finalDelete()">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { scholenRef } from "../../db";

export default {
  data: () => {
    return {
      school: "",
      selectedItem: "",
      search: "",
      headers: [
        {
          text: "Naam",
          align: "start",
          filterable: true,
          value: "schoolnaam",
        },
        { text: "School Admin", value: "sadmin", sortable: true },
        { text: "email", value: "sadminemail" },
        { text: "Tel", value: "tel" },
        {
          text: "Edit",
          align: "start",
          sortable: false,
          value: "edit",
          width: "120px",
        },
      ],
      dialog: false,
      deleteDialog: false,
      isEditing: false,
      formValues: {
        schoolnaam: "",
        email: "",
        sadmin: "",
        tel: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.formValues = {
        schoolnaam: "",
        email: "",
        sadmin: "",
        tel: "",
      };
    },
    gotoSchool: function (item) {
      this.$router.push({
        name: "overzichtSchool",
        params: { id: item[".key"] },
      });
    },
    saveSchool: function () {
      if (!this.isEditing) {
        scholenRef.push({
          schoolnaam: this.formValues.schoolnaam,
          sadmin: this.formValues.sadmin,
          sadminemail: this.formValues.email,
          tel: this.formValues.tel,
        });
      } else {
        scholenRef.child(this.selectedItem[".key"]).update({
          schoolnaam: this.formValues.schoolnaam,
          sadmin: this.formValues.sadmin,
          sadminemail: this.formValues.email,
          tel: this.formValues.tel,
        });
      }
      this.isEditing = false;
      this.dialog = false;
    },
    editSchool: function (item) {
      this.dialog = true;
      this.isEditing = true;
      this.formValues.schoolnaam = item.schoolnaam;
      this.formValues.email = item.sadminemail;
      this.formValues.sadmin = item.sadmin;
      this.formValues.tel = item.tel;
      this.selectedItem = item;
    },
    deleteSchool: function (item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },

    finalDelete: function () {
      scholenRef.child(this.selectedItem[".key"]).remove();
      this.deleteDialog = false;
    },
    schoolKeuze: function (k) {
      this.$store.commit("schoolKeuze", k);

      this.$router.push("/overzichtSchool");
    },
  },
  computed: {
    scholen() {
      return this.$store.state.scholenList;
    },
  },
};
</script>

<style scoped></style>
