<template>
  <v-card
    max-width="400"
    class="mx-auto mt-8"
    color="myred"
    rounded="lg"
    dark
    elevation="10"
    style="background: linear-gradient(0.4turn, #f17011, #f11100)"
  >
    <v-card-title> <h1>Attentie</h1> </v-card-title>
    <v-card-text>
      <h3>
        Voor het beste resultaat, gelieve deze app enkel op een mobiele telefoon
        te gebruiken. Dank u!
      </h3>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="white" dark @click="logout">
        <span style="color: brown">Sluiten</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$router.push("/");
    },
  },
};
</script>
