<template>
  <div class="about">
    <h1 class="pb-3">Scholenlijst</h1>
    <v-card flat>
      <p>Kies je school</p>
      <v-list rounded class="overflow-y-auto" max-height="600">
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in items" :key="item">
            <v-list-item-title @click="schoolKeuze(item)">
              {{ item }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      school: "",
      selectedItem: "",
    };
  },
  methods: {
    klasKeuze: function (k) {
      this.school = s;
      this.$store.commit("schoolKeuze", k);
      // ga naar Fotolijst-page
      // this.$router.push("/fotolijst");
    },
  },
  computed: {
    items() {
      return this.$store.state.scholen;
    },
  },
};
</script>
