<template>
  <v-container>
    <v-layout row>
      <v-layout v-if="loader" justify-center mt-16>
        <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-layout>

      <v-flex v-if="!loader" xs12 sm6 offset-sm3>
        <br />
        <v-card class="elevation-20">
          <v-card-title class="primary lighten-2 white--text">
            Login
          </v-card-title>
          <v-card-text>
            <br />
            <p>Je logt in met uw SO-account via Google in deze app.</p>
            <v-btn block class="primary" @click="onLogin">Login</v-btn>
          </v-card-text>
        </v-card>

        <br /><br />
        <v-card class="grey lighten-4 grey--text text--darken-1 elevation-20">
          <v-card-title class="myred lighten-3 white--text">
            Instructie
          </v-card-title>
          <v-card-text>
            <br />
            <p>
              Om in te loggen, moet je je SO-account gebruiken.<br /><br />
              Als dat om één of andere reden niet lukt, is je account nog niet
              gekend bij de diensten van Google. Hernieuw daarom je paswoord via
              deze
              <a
                href="https://so-apps.antwerpen.be/wachtwoordwijzig/"
                target="_blank"
                >link</a
              >.
            </p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      :timeout="timeout"
      :color="color"
      vertical="vertical"
      v-model="errorSnackbar"
    >
      {{ errorText }}
      <!--<v-btn outlined @click.native="errorSnackbar = false">
         <span class="white--text">Close</span></v-btn> -->
    </v-snackbar>
  </v-container>
</template>

<script>
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
// import db from "../db";
// import { usersRef, scholenRef } from "../db";

export default {
  data() {
    return {
      user: null,
      timeout: 10000,
      color: "myred",
      errorSnackbar: false,
      errorText: "Er ging iets fout bij het inloggen  ",
      loader: false,
    };
  },
  computed: {
    // currentUser() {
    //   if (this.$store.getters.getCurrentUser) {
    //     return this.$store.getters.getCurrentUser;
    //   }
    // },
    loadingDone() {
      this.$store.getters.getUsersLoadingDone;
    },
  },
  mounted() {
    //detirmene desktop or mobile
    if (window.innerWidth > 600) {
      this.$store.state.desktop = true;
    } else {
      this.$store.state.desktop = false;
    }

    //   this.$store.watch(
    //     this.$store.getters.getUsersLoadingDone,
    //     (loadingDone) => {
    //       console.log("State says it saw a " + loadingDone + " on loadingDone");
    //       if (loadingDone == true) {
    //         // commit the current user to the store
    //         console.log("user = ", this.user);
    //         this.$store.commit("setCurrentUser", this.user);
    //         console.log("User gets committed to the store");
    //         this.loader = false;
    //         // get the current userRoll
    //         // and go to the correct page
    //         console.log(
    //           "going to the correct path with an " +
    //             this.$store.getters.getCurrentUserRol
    //         );
    //         // switch (this.$store.getters.getCurrentUserRol) {
    //         //   case "Leerkracht":
    //         //     this.$router.push("/overzichtLKR");
    //         //     break;
    //         //   case "Admin":
    //         //     this.$router.push("/home");
    //         //     break;
    //         //   case "TA":
    //         //   case "Directie":
    //         //     this.$router.push("/overzichtfull");
    //         //     break;
    //         //   case "Coördinatie":
    //         //     this.$router.push("/kalender");
    //         //     break;
    //         // }
    //         if (
    //           this.$store.getters.getCurrentUserRol == "Admin" ||
    //           this.$store.getters.getCurrentUserRol == "Technisch adviseur"
    //         ) {
    //           this.$router.push("/overzicht");
    //         } else {
    //           // this.$router.push("/overzichtfull");
    //           this.$router.push("/home");
    //         }
    //       } else {
    //         this.$store.dispatch("logout");
    //       } // end if
    //     }
    //   );
  },
  methods: {
    onLogin() {
      var vm = this;
      console.log("Starting the logon screen");
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      auth.languageCode = "nl";
      signInWithPopup(auth, provider)
        .then((user) => {
          this.loader = true;
          this.user = user.user;
          this.$store.commit("setUser", user);
          // console.log(user.user);
          console.log(
            "%cYou are logged in as " + user.user.email,
            "color:#fff;background:#68F;border-radius:2px;padding:3px;"
          );
          const email = user.user.email;
          if (email.split("@")[1] == "so.antwerpen.be") {
            // login and password are ok
            // initing store and database
            // callback on usersRef will start the logon proces (in store.js)
            //console.log("-------- linking the databases -------");
            //this.$store.dispatch("setUsersRef", usersRef.orderByChild("name"));
            console.log("email:", email);
            this.$store.commit("directUserToPage", email);
            //console.log(vm.$store.state.scholenList);
          } else {
            vm.errorText = `${user.user.email} is geen SO-account! Probeer opnieuw met een geldig school account.  `;
            vm.errorSnackbar = true;
            // login the user out
            auth.signOut().then(() => {
              this.$router.push("/");
              this.loader = false;
              // console.log("You are logged out");
            });
          }
        })
        .catch((err) => {
          vm.errorText = err.message;
          vm.errorSnackbar = true;
        });
    },
  },
};
</script>

<style scoped></style>
