<template>
  <v-app>
    <v-app-bar app color="myorange" dark elevation="1  0" style="background: linear-gradient(0.9turn, #f12711, #f5af19)">
      <v-btn text icon color="white" @click="menuAction">
        <!-- <v-icon>mdi-arrow-left</v-icon>
        <v-icon>mdi-menu</v-icon> -->
        <v-icon>{{ menuIcon }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <v-toolbar-title>
        <span style="font-size: 10px">v 0.1.75 &nbsp; &nbsp;&nbsp; &nbsp;</span>
        Leerlingen Foto's</v-toolbar-title>
      <!-- <div class="d-flex align-center">
        <v-img
          alt="A Logo"
          class="ml-2"
          src="@/assets/logo-social-share.png"
          width="110px"
        />
      </div> -->
    </v-app-bar>

    <v-main>
      <v-container fluid> <router-view /></v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    menuAction() {
      if (this.menuIcon == "mdi-arrow-left") {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    menuIcon() {
      return this.$route.meta.nav;
    },
  },
  mounted() {
    //this.$store.dispatch("bindLKRListRef");
    this.$store.dispatch("bindScholenListRef");
    this.$store.dispatch("bindScholenObjRef");
    //this.$store.dispatch("bindSchoolLeerlingenListRef");
  },
};
</script>
