import { initializeApp } from "firebase/app";
import "firebase/compat/database";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDBYF7egyS7iAFZ1MM7_hXEniSKaGaUmos",
  authDomain: "sms-fotos.firebaseapp.com",
  databaseURL:
    "https://sms-fotos-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sms-fotos",
  storageBucket: "sms-fotos.appspot.com",
  messagingSenderId: "599293517811",
  appId: "1:599293517811:web:c2a286702d3e0c2957fc86",
};
const fbapp = firebase.initializeApp(firebaseConfig);
const db = fbapp.database();

//export the database object for components to use

export const prefsRef = db.ref("prefs");
export const lkrRef = db.ref("lkr");
export const scholenRef = db.ref("scholen");

//initialize firebase auth
const auth = getAuth();
//initialize firebase storage
export const storage = getStorage(fbapp);




//export the auth  object
export { auth };


