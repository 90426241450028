<template>
  <div class="about">
    <h1 class="pb-3">Scholenlijst</h1>
    <v-card flat>
      <p>Kies je School</p>
      <v-list rounded class="overflow-y-auto" max-height="600">
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in items" :key="item">
            <v-list-item-title @click="schoolKeuze(item)">
              {{ getSchoolNameByKey(item) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      school: "",
      selectedItem: "",
    };
  },
  methods: {
    schoolKeuze: function (k) {
      this.school = k;
      this.$store.commit("schoolKeuze", k);
      //this.$store.dispatch("bindLeerlingenKlas");
      // ga naar Fotolijst-page
      //this.$router.push("/fotolijst");
      //ga naar klaskeuze
      this.$router.push("/klaslijst");
    },
    getSchoolNameByKey(item) {
      // console.log("item = ", item);
      // console.log("scholenObj =", this.$store.state.scholenObj);
      // console.log("scholenObj[item] =", this.$store.state.scholenObj[item]);
      return this.$store.state.scholenObj[item].schoolnaam;
    },
  },
  computed: {
    items() {
      return this.$store.state.schoollijstvanleerkracht;
    },
  },
  mounted() {
    // this.$store.dispatch("bindScholenListRef");
    //this.$store.dispatch("bindScholenObjRef");
    // this.$store.dispatch("bindSchoolLeerlingenListRef");
  },
};
</script>
