var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-3",staticStyle:{"color":"#555"}},[_vm._v("Fotolijst - "+_vm._s(_vm.klas))]),_c('v-carousel',{attrs:{"hide-delimiters":"","touch":{
      left: function () { return _vm.sliderModel++; },
      right: function () { return _vm.sliderModel--; },
    }},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-large":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"70px","color":"white"}},[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-large":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"70px","color":"white"}},[_vm._v("mdi-chevron-right")])],1)]}}]),model:{value:(_vm.sliderModel),callback:function ($$v) {_vm.sliderModel=$$v},expression:"sliderModel"}},_vm._l((_vm.llnKlas),function(item){return _c('v-carousel-item',{key:item.internnummer},[_c('v-card',[(item.filename != null)?_c('v-img',{key:new Date().getTime(),staticClass:"\n          white--text align-end",attrs:{"eager":"","src":item.imgDownloadURL},on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_c('v-card-title',{staticClass:"pb-8"},[_vm._v(" "+_vm._s(item.voornaam)+" "+_vm._s(item.naam)+" "),_c('v-spacer'),_vm._v(" "+_vm._s(new Date(parseInt(item.timestamp)).getDate())+"-"+_vm._s(new Date(parseInt(item.timestamp)).getMonth() + 1)+"-"+_vm._s(new Date(parseInt(item.timestamp)).getFullYear())+" ")],1)],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":"/static/empty.png"},on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_c('v-card-title',{staticClass:"pb-8"},[_vm._v(" "+_vm._s(item.voornaam)+" "+_vm._s(item.naam)+" - "+_vm._s(item.internnummer)+" ")])],1)],1)],1)}),1),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*","id":"capture","capture":"camera"},on:{"change":_vm.onFileChange}}),_c('v-bottom-sheet',{model:{value:(_vm.bottomSheetModel),callback:function ($$v) {_vm.bottomSheetModel=$$v},expression:"bottomSheetModel"}},[_c('v-sheet',{staticClass:"text-center",staticStyle:{"border-radius":"20px 20px 0 0","margin-left":"2.5vw"},attrs:{"height":"80vh","hide-overlay":"","persistent":"","dark":"","max-width":"95vw"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.bottomSheetModel = !_vm.bottomSheetModel}}},[_vm._v(" close ")]),_c('camera')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }