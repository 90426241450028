import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Klaslijst from "../views/Klaslijst.vue";
import Fotolijst from "../views/Fotolijst.vue";
import GoMobile from "../views/GoMobile.vue";
import Scholenlijst from "../views/Scholenlijst.vue";
import tblScholen from "../views/admin/tblScholen.vue";
import overzichtSchool from "../views/admin/overzichtSchool.vue";
import LkrSchoolKeuze from "../views/LkrSchoolKeuze.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
    //component: tblScholen,
    //component: Klaslijst,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/gomobile",
    name: "GoMobile",
    component: GoMobile,
  },
  {
    path: "/tblscholen",
    name: "tblScholen",
    component: tblScholen,
  },
  {
    path: "/overzichtSchool",
    name: "overzichtSchool",
    component: overzichtSchool,
    meta: {
      nav: "mdi-arrow-left",
    },
  },
  {
    path: "/scholenlijst",
    name: "Scholenlijst",
    component: Scholenlijst,
  },
  {
    path: "/lkrSchoolKeuze",
    name: "lkrSchoolKeuze",
    component: LkrSchoolKeuze,
  },
  {
    path: "/klaslijst",
    name: "Klaslijst",
    component: Klaslijst,
    meta: {
      nav: "mdi-arrow-left",
    },
  },
  {
    path: "/fotolijst",
    name: "Fotolijst",
    meta: {
      nav: "mdi-arrow-left",
    },
    component: Fotolijst,
  },
  {
    name: "notFound",
    path: "/:pathMatch(.*)*",
    component: Login,
  },
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
