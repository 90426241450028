<template>
  <div>
    <h1 class="mb-3" style="color: #555">Fotolijst - {{ klas }}</h1>

    <v-carousel v-model="sliderModel" hide-delimiters :touch="{
      left: () => sliderModel++,
      right: () => sliderModel--,
    }">
      <template v-slot:prev="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon x-large>
          <v-icon style="font-size: 70px; color: white">mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon x-large>
          <v-icon style="font-size: 70px; color: white">mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <!-- :src="'/static/leerlingen/' + item.nummer + '.jpg'" -->
      <v-carousel-item v-for="item in llnKlas" :key="item.internnummer">
        <v-card>
          <v-img :key="new Date().getTime()" v-if="item.filename != null" eager :src="item.imgDownloadURL" class="
          white--text align-end" @click="$refs.fileInput.click()">
            <v-card-title class="pb-8">
              {{ item.voornaam }}
              {{ item.naam }}

              <!-- - -->
              <!-- {{ item.internnummer }} -->
              <!-- {{ item.filename }} -->
              <v-spacer></v-spacer>
              {{ new Date(parseInt(item.timestamp)).getDate() }}-{{ new Date(parseInt(item.timestamp)).getMonth() + 1
              }}-{{ new Date(parseInt(item.timestamp)).getFullYear()
}}

            </v-card-title>
          </v-img>

          <v-img v-else src="/static/empty.png" class="white--text align-end" @click="$refs.fileInput.click()">
            <v-card-title class="pb-8">
              {{ item.voornaam }}
              {{ item.naam }} - {{ item.internnummer }}
            </v-card-title>
          </v-img>
        </v-card>
      </v-carousel-item>
    </v-carousel>
    <input type="file" accept="image/*" id="capture" capture="camera" ref="fileInput" @change="onFileChange"
      style="display: none" />
    <v-bottom-sheet v-model="bottomSheetModel">
      <v-sheet class="text-center" style="border-radius: 20px 20px 0 0; margin-left: 2.5vw" height="80vh" hide-overlay
        persistent dark max-width="95vw">
        <!-- close button  -->
        <v-btn class="mt-6" text color="red" @click="bottomSheetModel = !bottomSheetModel">
          close
        </v-btn>
        <camera />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
//import camera from "@/components/camera";
import { uploadBytes } from "firebase/storage";
import { scholenRef, storage } from "../db";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";



export default {
  //components: { camera },
  data: () => {
    return {
      sliderModel: 0,
      bottomSheetModel: false,
      image: "",
      // IDstore: 0,
      file: null,
      scaledfile: null,
      progressValue: 0,
    };
  },
  methods: {
    openBottomSheet: function () {
      console.log("opening bottom sheet");
      this.bottomSheetModel = true;
    },

    // getting the file from the input
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      console.log("Starting the whole process");
      this.createImage(files[0]);
      this.file = files[0];
      console.log("File =", this.file);
      this.progressValue = 0;
    },
    // getting the image from the file
    async createImage(file) {
      // console.log("createImage");
      var vm = this;
      var reader = new FileReader();

      // reader.onload = (e) => {
      //   vm.image = e.target.result;
      // };
      // reader.readAsDataURL(file);

      // wait for the dataURL to be loaded from the file
      const dataURL = await new Promise((resolve) => {
        reader.onload = (e) => resolve(e.target.result);
        reader.readAsDataURL(file);
        // console.log("dataURL =", dataURL);
      });

      // Wait for the image to be loaded
      const img = new Image();
      await new Promise((resolve) => {
        img.onload = resolve;
        img.src = dataURL;
        //console.log("img.src =", img.src);
      });

      // Resize the image with a canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const [maxWidth, maxHeight] = [512, 682];
      const [imgWidth, imgHeight] = [img.naturalWidth, img.naturalHeight];

      const ratio = Math.min(maxWidth / imgWidth, maxHeight / imgHeight);

      canvas.width = imgWidth * ratio;
      canvas.height = imgHeight * ratio;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      this.image = canvas.toDataURL("image/jpeg", 0.8);
      // console.log("Image after scaling", this.image);
      // make it a file again
      let arr = this.image.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      this.scaledfile = new File([u8arr], `${this.file.name}`, { type: mime });
      console.log("scaledfile =", this.scaledfile);
      console.log("Calling indienen");
      this.indienen();
    },

    onProgress: function (e) {
      // this is an internal call in XHR to update the progress
      e.percent = (e.loaded / e.total) * 100;
      this.progressValue = e.percent;
      console.log(e.loaded, e.total);
      // this.$dispatch('onFileProgress', e);
    },
    // uploading the file

    indienen(event) {
      console.log("starting indienen")
      var vm = this;


      console.log("Starting upload with scaled file");
      // console.log("scaledfile =", this.scaledfile);
      if (this.scaledfile != null) {
        // console.log("this.scalefile was not NULL");
        var filename = this.IDstore + ".jpg" //+ "." + Date.now() + ".jpg";
        var file = this.scaledfile;
        //  upload bestand naar de server
        //  this.$dispatch('beforeFileUpload', file);

        //  START upload naar firebase storage
        console.log("starting the upload task")
        const storageRef = ref(storage, filename)
        const uploadTask = uploadBytesResumable(storageRef, file)
        // .then((snapshot) => {
        //   console.log('Uploaded a blob or file!');
        // });

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Progress tracking if needed
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            console.error('Upload error:', error);
          },
          () => {
            console.log("Upload completed successfully, get the download URL")
            let now = new Date().getTime();
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);
              // Store image URL in database
              scholenRef
                .child(vm.schoolKeuze)
                .child("leerlingen")
                .child(vm.llnKey)
                .child("filename")
                .set(filename);
              scholenRef
                .child(vm.schoolKeuze)
                .child("leerlingen")
                .child(vm.llnKey)
                .child("imgDownloadURL")
                .set(downloadURL);
              scholenRef
                .child(vm.schoolKeuze)
                .child("leerlingen")
                .child(vm.llnKey)
                .child("timestamp")
                .set(now);
              scholenRef
                .child(vm.schoolKeuze)
                .child("leerlingen")
                .child(vm.llnKey)
                .child("markAsNew")
                .set(true);
              scholenRef
                .child(vm.schoolKeuze)
                .child("leerlingen")
                .child(vm.llnKey)
                .child("photographer")
                .set(vm.$store.state.user.user.email);

            });
          }
        );


        /*  START PHP DEEL */
        // var form = new FormData();
        // var xhr = new XMLHttpRequest();
        // try {
        //   form.append("Content-Type", file.type || "application/octet-stream");
        //   form.append("file", file, filename);
        //   // form.append("filename", filename);
        // } catch (err) {
        //   // this.$dispatch('onFileError', file, err);
        //   console.log("Error", err);
        //   return;
        // }
        // return new Promise(
        //   function (resolve, reject) {
        //     xhr.upload.addEventListener("progress", this.onProgress, false);
        //     xhr.onreadystatechange = function () {
        //       console.log("readyStateChange : ", xhr.responseText);
        //       console.log("xhr.status : ", xhr.status);
        //       console.log("xhr.readyState : ", xhr.readyState);
        //       if (xhr.readyState < 4) {
        //         return;
        //       }
        //       if (xhr.status < 400) {
        //         var res = JSON.parse(xhr.responseText);
        //         this.$dispatch("onFileUpload", file, res);
        //         resolve(file);
        //         console.log("The upload is ready");
        //         console.log("filename =", filename);
        //         //set the date of picture taken in db
        //         //console.log("studentKey = ", vm.llnKey);

        //         scholenRef
        //           .child(vm.schoolKeuze)
        //           .child("leerlingen")
        //           .child(vm.llnKey)
        //           .child("filename")
        //           .set(filename);
        //         scholenRef
        //           .child(vm.schoolKeuze)
        //           .child("leerlingen")
        //           .child(vm.llnKey)
        //           .child("markAsNew")
        //           .set(true);
        //         scholenRef
        //           .child(vm.schoolKeuze)
        //           .child("leerlingen")
        //           .child(vm.llnKey)
        //           .child("photographer")
        //           .set(vm.$store.state.user.user.email);
        //       } else {
        //         var err = JSON.parse(xhr.responseText);
        //         err.status = xhr.status;
        //         err.statusText = xhr.statusText;
        //         //this.$dispatch('onFileError', file, err);
        //         reject(err);
        //       }
        //     }.bind(this);

        //     xhr.onerror = function () {
        //       console.log("onError : ", xhr.responseText);
        //       var err = JSON.parse(xhr.responseText);
        //       err.status = xhr.status;
        //       err.statusText = xhr.statusText;
        //       // this.$dispatch("onFileError", file, err);
        //       reject(err);
        //     }.bind(this);

        //     xhr.open(
        //       "POST",
        //       "https://smsfotos.slc-mul.online/static/upload.php",
        //       true
        //     );
        //     if (this.headers) {
        //       for (var header in this.headers) {
        //         xhr.setRequestHeader(header, this.headers[header]);
        //       }
        //     }
        //     xhr.send(form);
        //     // this.$dispatch('afterFileUpload', file);
        //   }.bind(this)
        // );
      }
    },
  },

  computed: {
    klas() {
      return this.$store.state.klaskeuze;
    },
    llnKlas() {
      return this.$store.getters.llnKlas;
    },
    IDstore() {
      return this.llnKlas[this.sliderModel].internnummer;
    },
    llnKey() {
      return this.llnKlas[this.sliderModel][".key"];
    },
    schoolKeuze() {
      return this.$store.state.schoolKeuze;
    },
  },
};
</script>
