<template>
  <div class="about">
    <h1 class="pb-3">School: {{ school.schoolnaam }}</h1>
    <v-row>
      <v-col>
        <v-card @click="view = true" rounded="lg" dark color="#fc867a" elevation="5"
          style="background: linear-gradient(0.4turn, #f12711, #f5af19)">
          <v-card-title>Leerkrachten <v-spacer></v-spacer>{{ schoolLeerkrachtenList.length }}</v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-card @click="view = false" rounded="lg" dark color="#64d6c5" elevation="5"
          style="background: linear-gradient(0.4turn, #085078, #85d8ce)">
          <v-card-title>Leerlingen<v-spacer></v-spacer>{{ schoolLeerlingenList.length }}</v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-card rounded="lg" dark color="#bd64ce" elevation="5"
          style="background: linear-gradient(0.4turn, #7f00ff, #e100ff)">
          <v-card-title>Fotos<v-spacer></v-spacer>{{ aantalFotos }}</v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-card rounded="lg" dark color="#62cbe0" elevation="5"
          style="background: linear-gradient(0.4turn, #45b649, #dce35b)">
          <v-card-title>Nieuwe Fotos<v-spacer></v-spacer>
            {{ aantalNieuweFotos }}&nbsp;&nbsp; &nbsp;
            <v-btn fab dark x-small outlined elevation="0" @click="downloadAllImages()">
              <v-icon v-if="isDownloading == false">mdi-download</v-icon>
              <p v-else style="margin-top:16px">{{ downloadCounter }}</p>
            </v-btn>
            <!-- &nbsp;
            <v-btn fab dark x-small outlined elevation="0" @click="resetNew()">
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <br />

    <br />

    <v-card v-show="!view">
      <v-card-title>
        Leerlingen
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table dense :headers="llnheaders" :items="schoolLeerlingenList" :search="search" :sort-by="['klas', 'naam']"
        :sort-desc="false">
        <template v-slot:[`item.startIndienen`]="{ item }">
          <v-icon v-if="item.startIndienen">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.markAsNew`]="{ item }">
          <v-icon v-if="item.markAsNew">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <!-- todo check to see if there is a foto by datum -->
          <v-avatar size="24">
            <v-img :src="item.imgDownloadURL" @mouseenter="hoveringImg(item.imgDownloadURL)"
              @mouseleave="leavingImg()"></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <!-- <v-icon small class="mr-2" color="green" @click="editlln(item)">mdi-pencil</v-icon> -->
          <!-- <v-icon small class="mr-2" color="red" @click="deletelln(item)">mdi-delete</v-icon> -->
          <!-- <v-icon small class="mr-2" color="blue" @click="gotolkr(item)"
              >mdi-arrow-right-bold-circle</v-icon
            > -->
        </template></v-data-table>
    </v-card>
    <v-card v-show="view">
      <v-card-title>
        Leerkrachten
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table dense :headers="lkrheaders" :items="schoolLeerkrachtenList" :search="search" :sort-by="['naam']"
        :sort-desc="false">
        <template v-slot:[`item.edit`]="{ item }">
          <!-- <v-icon small class="mr-2" color="green" @click="editlln(item)"
            >mdi-pencil</v-icon
          > -->
          <v-icon small class="mr-2" color="red" @click="deletelkr(item)">mdi-delete</v-icon>
          <!-- <v-icon small class="mr-2" color="blue" @click="gotolln(item)"
              >mdi-arrow-right-bold-circle</v-icon
            > -->
        </template></v-data-table>
    </v-card>

    <!-- <v-btn
      color="pink"
      dark
      absolute
      bottom
      right
      fab
      style="margin-bottom: 60px; margin-right: 20px"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn> -->

    <v-speed-dial v-model="fab" direction="left" open-on-hover transition="slide-x-reverse-transition" absolute bottom
      right>
      <template v-slot:activator>
        <v-btn v-model="fab" color="pink" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-plus </v-icon>
        </v-btn>
      </template>
      <v-btn dark color="green" style="background: linear-gradient(0.4turn, #085078, #85d8ce)" @click="importLLN()">
        Import Leerlingen
      </v-btn>
      <v-btn dark color="red" style="background: linear-gradient(0.4turn, #f12711, #f5af19)" @click="importLKR()">
        Import Leerkrachten
      </v-btn>
    </v-speed-dial>
    <!-- fotoview-->
    <v-img class="viewedImage" :src="viewedImage" :width="400" v-if="viewingImage"></v-img>

    <!-- deleteDialog -->
    <v-dialog max-width="500" v-model="deleteDialog">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar v-if="deleteMode == 'lkr'" color="error" dark class="text-h5">Verwijder de leerkracht</v-toolbar>
          <v-toolbar v-else color="error" dark class="text-h5">Verwijder de leerling</v-toolbar>
          <v-card-text>
            <div class="text-h4 mt-8 mb-2">Ben je zeker?</div>
            <p>
              Deze actie kan niet ongedaan gemaakt worden. Deze actie is
              permanent. Herimporteer de leerkrachten wanneer nodig.
            </p>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn outlined color="red" @click="dialog.value = false">Close</v-btn>
            <v-btn color="red white--text" @click="finalDelete()">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { scholenRef, lkrRef, storage } from "../../db";
import { ref, listAll, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import dialog from "open-file-dialog";
import axios from "axios";
import JSZip from "jszip";

export default {
  data: () => {
    return {
      view: false, //lln or lkr
      fab: "",
      selectedItem: "",
      deleteMode: "",
      search: "",
      lkrheaders: [
        {
          text: "Naam",
          align: "start",
          filterable: true,
          value: "naam",
        },
        { text: "Voornaam", value: "voornaam", sortable: true },
        { text: "email", value: "email" },

        {
          text: "Edit",
          align: "start",
          sortable: false,
          value: "edit",
          width: "120px",
        },
      ],
      llnheaders: [
        {
          text: "Klas",
          value: "klas",
          align: "start",
          sortable: true,
          width: "150px",
        },
        {
          text: "Intern Nummer",
          value: "internnummer",
          sortable: true,
          align: "start",
          width: "100px",
        },
        {
          text: "Naam",
          align: "end",
          sortable: true,
          value: "naam",
          width: "200px",
        },
        {
          text: "Voornaam",
          value: "voornaam",
          align: "start",
          sortable: true,
          width: "200px",
        },
        {
          text: "Email",
          value: "email",
          align: "start",
          sortable: true,
          width: "350px",
        },

        {
          text: "Fotograaf",
          value: "photographer",
          align: "start",
          sortable: true,
          width: "350px",
        },

        {
          text: "Afbeelding",
          value: "image",
          sortable: false,
          align: "center",
        },

        {
          text: "Uploaded",
          value: "markAsNew",
          sortable: true,
          width: "110px",
          align: "center",
        },
        // {
        //   text: "Edit",
        //   align: "start",
        //   sortable: false,
        //   value: "edit",
        //   width: "80px",
        // },
      ],
      dialog: false,
      deleteDialog: false,
      isEditing: false,
      formValues: {
        schoolnaam: "",
        email: "",
        sadmin: "",
        tel: "",
      },
      importCounter: 0,
      updateCounter: 0,
      downloadCounter: 0,
      isDownloading: false,
      viewedImage: "", viewingImage: false,
    };
  },
  methods: {
    deletelkr: function (item) {
      this.deleteMode = "lkr";
      this.deleteDialog = true;
      this.selectedItem = item;
    },
    deletelln: function (item) {
      this.deleteMode = "lln";
      this.deleteDialog = true;
      this.selectedItem = item;
    },
    finalDelete: function () {
      if (this.deleteMode == "lkr") {
        this.deleteLKR();
        scholenRef
          .child(this.$store.state.schoolKeuze)
          .child("leerkrachten")
          .child(this.selectedItem[".key"])
          .remove();
      } else {
        scholenRef
          .child(this.$store.state.schoolKeuze)
          .child("leerlingen")
          .child(this.selectedItem[".key"])
          .remove();
      }
      this.deleteDialog = false;
    },
    importLKR: function () {
      // open a file (json converted xls from SMT)
      this.importCounter = 0;
      var vm = this;
      dialog(
        {
          multiple: false,
          accept: "application/json",
        },
        function (file) {
          if (!file.length) {
            console.log("No file");
            return;
          }
          let reader = new FileReader();
          reader.onerror = function (e) {
            console.log(e);
          };
          reader.onload = function (e) {
            let json = JSON.parse(e.target.result);
            // iterate over the json to store it in firebase
            for (let i = 0; i < json.length; i++) {
              let leerkracht = json[i];
              vm.addLKRtoDB(leerkracht);
            }
            console.log(
              "Er werden " + vm.importCounter + " leerkrachten geïmporteerd"
            );
          };
          reader.readAsText(file[0]);
        }
      );
    },
    addLKRtoDB: function (item) {
      //console.log(item.email);
      // check to see if the LKR is already in the database
      if (this.schoolLeerkrachtenEmailLijst.indexOf(item.email) == -1) {
        // adding LKR
        //console.log("Adding lkr", item.email);
        this.importCounter++;
        scholenRef.child(this.schoolKeuze).child("leerkrachten").push(item);
      } else {
        // update lkr maybe?
        // console.log("leerkracht already in db", item.email);
        return;
      }
    },
    importLLN: function () {
      // open a file (json converted xls from SMT)
      this.importCounter = 0;
      this.updateCounter = 0;
      var vm = this;
      dialog(
        {
          multiple: false,
          accept: "application/json",
        },
        function (file) {
          if (!file.length) {
            console.log("No file");
            return;
          }
          let reader = new FileReader();
          reader.onerror = function (e) {
            console.log(e);
          };
          reader.onload = function (e) {
            let json = JSON.parse(e.target.result);
            // iterate over the json to store it in firebase
            for (let i = 0; i < json.length; i++) {
              let leerling = json[i];
              vm.addLLNtoDB(leerling);
            }
            console.log(
              "Er werden " + vm.importCounter + " leerlingen geïmporteerd"
            );
            console.log(
              "Er werden " + vm.updateCounter + " leerlingen geüpdate"
            );

            // wissen van alle oudere lln accounts
            // importYear is Year -1
            // WHY????  Nieuwe fotos van reeds bestaande leerlingen?  
            let year = new Date().getFullYear();
            if (new Date().getMonth() < 8) {
              year -= 1;
            }
            year -= 1;
            // loop door de database en wis alle lln met importYear == year
            // of lln zonder importYear
            scholenRef
              .child(vm.schoolKeuze)
              .child("leerlingen")
              .once("value")
              .then(function (snapshot) {
                //console.log(snapshot.val());

                snapshot.forEach(function (item) {
                  //console.log(item.val().importYear);
                  //console.log("year", year);
                  if (item.val().importYear == year) {
                    // console.log("importYear found", item.val().importYear);
                    // console.log(item.val());
                    // console.log(item.key);
                    //let k = Object.keys(snapshot.val())[0];
                    scholenRef
                      .child(vm.schoolKeuze)
                      .child("leerlingen")
                      .child(item.key)
                      .remove();
                  }
                });
              });
          };
          reader.readAsText(file[0]);
        }
      );
    },
    addLLNtoDB: function (item) {
      // lln krijgen een importYear mee
      // nieuwe en geupdate krijgen nieuw jaar mee
      // daarna alle oudere wissen uit de database
      // oudere jaren worden verijderd uit de databases

      // adding year to the item
      let year = new Date().getFullYear();
      if (new Date().getMonth() < 8) {
        year -= 1;
      }
      item.importYear = year;

      //console.log(item.email);
      // check to see if the LLN is already in the database
      if (this.schoolLeerlingenEmailLijst.indexOf(item.email) == -1) {
        // Nieuwe Leerling!!
        // adding LLN
        console.log("Adding lln", item.email);
        this.importCounter++;
        scholenRef.child(this.schoolKeuze).child("leerlingen").push(item);
      } else {
        // console.log("updating LLN", item);
        // bestaande leerling
        // klas altijd updaten
        // zoek de leerling op in de database
        // update de klas vanuit item en importYear naar year
        let vm = this;
        this.updateCounter++;
        //console.log(this.updateCounter);
        scholenRef
          .child(this.schoolKeuze)
          .child("leerlingen")
          .orderByChild("email")
          .equalTo(item.email)
          .once("value")
          .then(function (snapshot) {
            let k = Object.keys(snapshot.val())[0];
            scholenRef
              .child(vm.schoolKeuze)
              .child("leerlingen")
              .child(k)
              .child("klas")
              .set(item.klas);
            scholenRef
              .child(vm.schoolKeuze)
              .child("leerlingen")
              .child(k)
              .child("importYear")
              .set(year);
          });

        // console.log("leerling already in db", item.email);
        return;
      }
    },
    hoveringImg(imgURL) {
      if (imgURL != undefined) {
        this.viewedImage = imgURL
        this.viewingImage = true
      }
    },
    leavingImg() {
      this.viewedImage = ""
      this.viewingImage = false
    },
    async downloadAllImages() {
      if (this.isDownloading == false) {
        const imagesList = []
        this.schoolLeerlingenList.forEach(function (ll) {
          if (ll.filename != undefined) {
            imagesList.push({ filename: ll.filename, url: ll.imgDownloadURL })
          }
        })
        console.log("imagesList", imagesList)
        const zip = new JSZip();
        const folder = zip.folder("smsfotos");
        this.downloadCounter = 0
        this.isDownloading = true
        for (const imageItem of imagesList) {
          const response = await fetch(imageItem.url);
          const data = await response.blob();
          folder.file(imageItem.filename, data);
          this.downloadCounter++
          //console.log("downloadCounter", this.downloadCounter)
        }
        const content = await zip.generateAsync({ type: "blob" });
        const url = window.URL.createObjectURL(content);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "smsfotos.zip");
        document.body.appendChild(link);
        link.click();
        this.isDownloading = false
      }
    },

    downloadNewZip() {
      //first run zipper, then get the zip file
      axios
        .get("https://smsfotos.slc-mul.online/static/zipperuiimages.php")
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "Zip file created successfully") {
            axios
              .get(
                "https://smsfotos.slc-mul.online/static/smsfotos-archive.zip",
                {
                  responseType: "blob",
                }
              )
              .then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "smsfotos-archive.zip");
                document.body.appendChild(link);
                link.click();
              });
          }
        });
    },

    resetNew() {
      // loop door alle leerlingen
      // marksAsNew => false
      // startIndienen => false
      console.log("Resetting Stuff");
      let vm = this;
      console.time();
      this.schoolLeerlingenList.forEach(function (l) {
        let key = l[".key"];
        scholenRef
          .child(vm.schoolKeuze)
          .child("leerlingen")
          .child(key)
          .child("markAsNew")
          .set(false);
        scholenRef
          .child(vm.schoolKeuze)
          .child("leerlingen")
          .child(key)
          .child("startIndienen")
          .set(false);
      });
      console.timeEnd();
    },
  },
  mounted() {
    this.$store.dispatch("bindSchoolLeerkrachtenListRef");
    this.$store.dispatch("bindSchoolLeerlingenListRef");
  },
  computed: {
    lkrList() {
      return this.$store.state.lkrList;
    },
    school() {
      return this.$store.getters.school;
    },
    schoolKeuze() {
      return this.$store.state.schoolKeuze;
    },
    schoolLeerkrachten() {
      return this.$store.getters.schoolLeerkrachten;
    },
    schoolLeerkrachtenList() {
      return this.$store.state.schoolLeerkrachtenList;
    },
    schoolLeerkrachtenEmailLijst() {
      return this.$store.getters.schoolLeerkrachtenEmailLijst;
    },
    schoolLeerlingenList() {
      return this.$store.state.schoolLeerlingenList;
    },
    schoolLeerlingenEmailLijst() {
      return this.$store.getters.schoolLeerlingenEmailLijst;
    },

    aantalNieuweFotos() {
      return this.$store.getters.aantalNieuweFotos;
    },

    aantalFotos() {
      return this.$store.getters.aantalFotos;
    },
  },
};
</script>

<style scoped>
.viewedImage {
  position: absolute;
  top: 25%;
  left: 50%;
  width: 500px;
  height: 500px;
  z-index: 9999;

  border-radius: 50px;
  box-shadow: 0 0 30px 20px rgba(0, 0, 0, 0.2);
}
</style>
