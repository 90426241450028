import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firebaseAction } from "vuexfire";
import { lkrRef, scholenRef, auth } from "../db";
import routes from "../router/index";
import { signOut } from "firebase/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    superadmin: "dirk.simons@so.antwerpen.be",
    desktop: false,
    //lln: [],
    //klassen: [],
    klaskeuze: "",
    schoolKeuze: "",
    llnKlas: [],
    scholenList: [],
    lkrList: [],
    scholenObj: {},
    schoollijstvanleerkracht: [],
    schoolLeerkrachtenList: [],
    schoolLeerlingenList: [],
    newFotos: [],
    aantalFotos: 0,
    user: null,
  },
  getters: {
    llnKlas: (state) => state.llnKlas,
    //school: (state) => state.scholenObj[state.schoolKeuze],
    school: (state) => {
      let newList = state.scholenList.filter((temp) => {
        return temp[".key"] == state.schoolKeuze;
      });
      return newList[0];
    },
    schoolLeerkrachten: (state) =>
      state.scholenObj[state.schoolKeuze].leerkrachten,
    schoolLeerkrachtenEmailLijst: function (state) {
      let list = [];
      if (state.scholenObj[state.schoolKeuze].leerkrachten) {
        Object.values(state.scholenObj[state.schoolKeuze].leerkrachten).forEach(
          (leerkracht) => {
            list.push(leerkracht.email);
          }
        );
      }
      return list;
    },
    aantalFotos: function (state, getters) {
      let aantal = 0;

      if (getters.school.leerlingen) {
        Object.values(getters.school.leerlingen).forEach(function (leerling) {
          if (leerling.filename != null) aantal++;
        });
      }
      return aantal;
    },
    aantalNieuweFotos: function (state, getters) {
      let aantal = 0;
      let counter = 0;
      if (getters.school.leerlingen) {
        Object.values(getters.school.leerlingen).forEach(function (leerling) {
          counter++;
          //console.log(counter, aantal, leerling);
          if (leerling.markAsNew == true) {
            aantal++;
          }
        });
      }
      return aantal;
    },
    schoolLeerlingenEmailLijst: function (state) {
      let list = [];
      if (state.scholenObj[state.schoolKeuze].leerlingen) {
        Object.values(state.scholenObj[state.schoolKeuze].leerlingen).forEach(
          (leerling) => {
            list.push(leerling.email);
          }
        );
      }
      return list;
    },
    klassenLijst: function (state) {
      // haal alle unieke klasnamen op vuinuit de leerlingenList
      let list = [];
      state.schoolLeerlingenList.forEach((leerling) => {
        if (list.indexOf(leerling.klas) === -1) {
          list.push(leerling.klas);
        }
      });
      return list.sort();
    },
    getSchoolNameByKey: function (state, key) {
      return function (key) {
        let school = state.scholenList.filter((temp) => {
          return temp[".key"] == key;
        });
        return school.schoolnaam;
      };
    },
  },
  mutations: {
    ...vuexfireMutations,
    setUser(state, payload) {
      state.user = payload;
      //Log out the user state
      console.log("user =", state.user);
    },
    schoolKeuze(state, payload) {
      state.schoolKeuze = payload;
    },
    setKlassen(state, payload) {
      state.lln = payload;
      payload.forEach((p) => {
        if (state.klassen.indexOf(p.klas) == -1) {
          state.klassen.push(p.klas);
        }
      });
    },
    klasKeuze(state, payload) {
      state.klaskeuze = payload;
      // maak llnKlas array klas
      // state.llnKlas = [];
      // state.schoolLeerlingenList.forEach((l) => {
      //   if (state.klaskeuze == l.klas) {
      //     state.llnKlas.push(l);
      //   }
      // });
    },
    directUserToPage(state, payload) {
      console.log("Finding your page");
      console.log("payload:", payload);
      if (state.desktop) {
        // DESKTOP ONLY
        // only super and school admins allowed!!
        //check for superadmin
        if (payload == state.superadmin) {
          console.log(
            "%cYou are a superadmin",
            "color:#fff;background:#0F6;border-radius:2px;padding:3px;"
          );
          state.userRole = "superadmin";
          // goto tblScholen
          // console.log("Going to tblScholen");
          routes.push("/tblscholen");
        } else {
          //TODO  Nakijken voor meerdere scholen!!!
          state.scholenList.forEach((school) => {
            if (payload == school.sadminemail) {
              console.log(
                "%cYou are a schooladmin",
                "color:#fff;background:#0F6;border-radius:2px;padding:3px;"
              );
              state.userRole = "schooladmin";
              console.log("Schoolkeuze = ", school[".key"]);
              state.schoolKeuze = school[".key"];
              // goto tblScholen
              console.log("Going to tblScholen");
              routes.push("/overzichtSchool");
            } else {
              // you are noone... force to take a mobile!
              // please run this app on a mobile device like iPhone or Android
              console.log("please go mobile");
              routes.push("/gomobile");
            }
          });
        }
      } else {
        // MOBILE ONLY
        // check for teacher of school
        // loop through schools and get leerkrachten
        // check voor meerdere scholen

        let list = [];
        state.scholenList.forEach((school) => {
          if (school.leerkrachten) {
            //console.log(school.schoolnaam, payload);
            Object.values(school.leerkrachten).forEach((leerkracht) => {
              if (leerkracht.email == payload) {
                //console.log("Found a school");
                list.push(school[".key"]);
              }
            });
          }
        });
        //console.log("schoollijstvanleerkracht", list);
        state.schoollijstvanleerkracht = list;
        if (list.length > 0) {
          console.log(
            "%cYou are a teacher and have " + list.length + " schools",
            "color:#fff;background:#0F6;border-radius:2px;padding:3px;"
          );
          state.userRole = "teacher";
          if (list.length == 1) {
            state.schoolKeuze = list[0];
            // goto klaslijst
            console.log("Going to klaslijst");
            routes.push("/klaslijst");
          } else {
            // toon meerdere scholen voor schoolkeuze
            console.log("Going to lkrSchoolKeuze");
            routes.push("/lkrSchoolKeuze");
          }
        }
      }
    },
  },

  actions: {
    async logout(context) {
      await signOut(auth);

      context.commit("setUser", null);
    },
    // loadLln({ commit }) {
    //   axios.get("/static/lln2022-full.json").then((res) => {
    //     commit("setKlassen", res.data);
    //   });
    // },
    bindScholenListRef: firebaseAction((context) => {
      return context.bindFirebaseRef("scholenList", scholenRef);
    }),
    bindLKRListRef: firebaseAction((context) => {
      return context.bindFirebaseRef("lkrList", lkrRef);
    }),
    bindScholenObjRef: firebaseAction((context) => {
      return context.bindFirebaseRef("scholenObj", scholenRef);
    }),
    bindSchoolLeerkrachtenListRef: firebaseAction((context) => {
      return context.bindFirebaseRef(
        "schoolLeerkrachtenList",
        scholenRef.child(context.state.schoolKeuze).child("leerkrachten")
      );
    }),
    bindSchoolLeerlingenListRef: firebaseAction((context) => {
      console.log(
        "haal alle leerling van een school op",
        context.state.schoolKeuze
      );
      return context.bindFirebaseRef(
        "schoolLeerlingenList",
        scholenRef.child(context.state.schoolKeuze).child("leerlingen")
      );
    }),
    bindLeerlingenKlas: firebaseAction((context) => {
      return context.bindFirebaseRef(
        "llnKlas",
        scholenRef
          .child(context.state.schoolKeuze)
          .child("leerlingen")
          .orderByChild("klas")
          .equalTo(context.state.klaskeuze)
      );
    }),
  },
});
